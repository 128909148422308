import { apiEmailUniqueValidation } from "../services/api";

import { consentPropertyType, cookieConsentPropertyType } from "../constants";

export const CONST_GENDER_FEMALE = 2;
export const CONST_GENDER_MALE = 1;
export const REGISTRATION_CONFIRMED = 1;
export const POST_REG_FUNNEL_SHOW = 1;
export const POST_REG_FUNNEL_SHOW_REQUIRED = 2;
export const MIN_AGE = 18;
export const MAX_AGE = 90;
export const TOKEN_EXPIRE = 30;
//export const AUTH_COOKIE_NAME = 'auth_key',
export const BACK_URL = "/";
export const NEXT_URL = "/";
export const COOKIE_NAME = "lad_co_r";
export const COOKIE_QUERY_NAME = "lad_q_r";
export const queryTrackParams = [
  "type_source",
  "partner",
  "subid",
  "site",
  "sub1",
  "sub2",
  "sub3",
  "offer_id",
  "clickid",
  "tid",
  "aff_id",
  "network_id",
  "utm_source",
  "utm_medium",
  "pp",
];
export const registrationUserData = null;
export const GTM_REG_METHOD_MAIL = "email";
export const GTM_REG_METHOD_PHONE = "phone";
export const GTM_REG_METHOD_GOOGLE = "social_google";

export const CONST_USERNAME_MIN_SYMBOLS = 2;
export const CONST_USERNAME_MAX_SYMBOLS = 255;
export const CONST_EMAIL_MAX_SYMBOLS = 255;
export const MAX_PASSWORD_LENGTH = 255;
export const MIN_PASSWORD_LENGTH = 5;
export const REG_ENDPOINT = "/api/v2/registration/";
export const EMAIL_VALIDATION_ENDPOINT = "/api/v2/validation/email/";

// Change this config for all lending
export const COOKIE_PATH = "/lnd/lt2/";

export const getHostName = () => {
  return window.location.hostname;
};

export const getDate = () => {
  let dates = [];

  for (let i = 1; i <= 31; i++) {
    dates[i] = i;
  }

  return dates;
};

export const getMonths = () => {
  const monthsLabel = [
    "Jan.",
    "Feb.",
    "Mar.",
    "Apr.",
    "May",
    "Jun.",
    "Jul.",
    "Aug.",
    "Sep.",
    "Oct.",
    "Nov.",
    "Dec.",
  ];
  let months = [];

  for (let i = 0; i < 12; i++) {
    months[i + 1] = monthsLabel[i];
  }

  return months;
};

export const getYears = () => {
  const year = parseInt(new Date().getFullYear()) - MIN_AGE,
    startFrom = parseInt(year) - (parseInt(MAX_AGE) - parseInt(MIN_AGE));
  let years = [];

  for (let i = year; i > startFrom; i--) {
    years[i] = i;
  }

  return years.reverse();
};

export const getTimeZone = () => {
  const offset = new Date().getTimezoneOffset();
  let hrs = parseInt(Math.abs(offset / 60)),
    min = Math.abs(offset % 60);

  hrs = hrs < 10 ? "0" + hrs : hrs;
  min = min < 10 ? "0" + min : min;

  if (offset < 0) {
    return "+" + hrs + ":" + min;
  } else {
    return "-" + hrs + ":" + min;
  }
};

export const getClientLanguage = () => {
  const userLang = navigator.language || navigator.userLanguage;

  if (!userLang) {
    return "";
  }

  return userLang;
};

export const setCookie = (name, value, exdays, path, subdomains) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  let domain = getHostName();

  if (subdomains) {
    domain = domain.split(".");
    domain = domain[domain.length - 2] + "." + domain[domain.length - 1];
  }

  document.cookie =
    name +
    "=" +
    value +
    ";" +
    expires +
    ";path=" +
    path +
    (subdomains ? ";domain=." + domain : "");
};

export const getCookie = (cname) => {
  const name = cname + "=",
    decodedCookie = decodeURIComponent(document.cookie),
    ca = decodedCookie.split(";");

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
};

export const deleteCookie = (name) => {
  setCookie(name, "", -1, COOKIE_PATH, true);
  setCookie(name, "", -1, COOKIE_PATH);
};

export const existCookie = (name) => {
  const cookie = getCookie(name);

  if (!cookie || cookie.length <= 0) {
    return false;
  }

  return true;
};

/**
 * Validate user age
 * @param {number} month Month number
 * @param {number} day Day number
 * @param {number} year Year number
 * @return {{error: null|string, status: boolean}}
 */
export const validateAge = (month, day, year) => {
  const result = {
    status: false,
    error: null,
  };

  if (!month || !day || !year) {
    result.error = "The full date of birth must be given.";
    return result;
  }

  if (!isValidYear(year) || !isValidDay(day) || !isValidMonth(month)) {
    result.error = "The full date of birth must be given.";
    return result;
  }

  const age = Math.floor(
    (new Date() -
      new Date(
        year +
          "-" +
          (month < 10 ? "0" + month : month) +
          "-" +
          (day < 10 ? "0" + day : day)
      ).getTime()) /
      3.15576e10
  );

  if (age < MIN_AGE) {
    result.error = `Only users over ${MIN_AGE} years old have access to the platform.`;
    return result;
  }

  result.status = true;

  return result;
};

export const isValidGender = (gender) => {
  return !(
    typeof gender === "undefined" ||
    (gender != CONST_GENDER_FEMALE && gender != CONST_GENDER_MALE)
  );
};

/**
 * Validate username
 * @param {string} name User name
 * @return {{error: null, status: boolean}}
 */
export const validateName = (name) => {
  const USERNAME_REGEX = /^[a-zA-Z\s’'-]+$/;
  const result = {
    status: false,
    error: "",
  };

  if (
      !name ||
      name.length === 0 ||
      (typeof name.trim !== "undefined" && name.trim().length === 0)
  ) {
    result.error = "Please enter your name";
    return result;
  }

  if (typeof name.trim !== "undefined") {
    name = name.trim();
  }

  if (name.length < CONST_USERNAME_MIN_SYMBOLS) {
    result.error = `Your name must contain at least ${CONST_USERNAME_MIN_SYMBOLS} letters.`;
    return result;
  } else if (name.length > CONST_USERNAME_MAX_SYMBOLS) {
    result.error = `The username must contain less than ${CONST_USERNAME_MAX_SYMBOLS} characters.`;
    return result;
  } else if (!USERNAME_REGEX.test(name)) {
    result.error = `Only letters (A-z, a-z) are allowed `;
    return result;
  }

  result.status = true;
  return result;
};

export const isValidMonth = (month) => {
  if (typeof month === "undefined" || month != parseInt(month)) return false;

  return !(month.length <= 0 || month < 1 || month > 12);
};

export const isValidDay = (day) => {
  if (typeof day === "undefined" || day != parseInt(day)) return false;

  return !(day.length <= 0 || day < 1 || day > 31);
};

export const isValidYear = (year) => {
  if (typeof year === "undefined" || year != parseInt(year)) return false;
  else {
    const today = new Date();
    const age = today.getFullYear() - parseInt(year);

    if (age < MIN_AGE || age > MAX_AGE) {
      return false;
    }
  }

  return true;
};

export const goBackUrl = () => {
  window.location.href = BACK_URL;
};

/**
 * Validate user email
 * @param {string} email User email
 * @param {function} cb UCallback function
 */
export const validateEmail = (email, cb) => {
  const reg =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const result = {
    status: false,
    error: null,
  };

  if (!email || email.length <= 0) {
    result.error = "This field cannot be empty.";
    cb(result);
    return;
  }

  if (typeof email.trim !== "undefined") {
    email = email.trim();
  }

  if (email.length >= CONST_EMAIL_MAX_SYMBOLS) {
    result.error = `Email must contain less than ${CONST_EMAIL_MAX_SYMBOLS} characters.`;
    cb(result);
    return;
  } else if (!reg.test(email)) {
    result.error = `Your email address is not valid. Please, check it`;
    cb(result);
    return;
  }

  apiEmailUniqueValidation({ email }, (response) => {
    if (response.status === 422) {
      const data = JSON.parse(response.responseText);
      if (data.errors && data.errors.length > 0) {
        let errorText = "";
        for (let i = 0; i < data.errors.length; i++) {
          const error = data.errors[i];
          errorText += i > 0 ? " " + error.message : error.message;
          console.log(errorText);
        }
        result.error = errorText;
        result.status = false;
        cb(result);
        return;
      }
    } else {
      result.status = true;
    }
    cb(result);
  });
};

/**
 * Validate password
 * @param {string} password Password text
 * @return {{error: null|string, status: boolean}}
 */
export const validatePassword = (password) => {
  const result = {
    status: false,
    error: null,
  };

  if (
    !password ||
    password.length <= 0 ||
    (typeof password.trim !== "undefined" && password.trim().length === 0)
  ) {
    result.error = "The password cannot be empty.";
    return result;
  }

  if (password.length > MAX_PASSWORD_LENGTH) {
    result.error = `The password cannot be more than ${MAX_PASSWORD_LENGTH} characters.`;
    return result;
  }

  if (password.length < MIN_PASSWORD_LENGTH) {
    result.error = `The password cannot be less than ${MIN_PASSWORD_LENGTH} symbols.`;
    return result;
  }

  result.status = true;
  return result;
};

export const gtmRegistration = (data) => {
  if (!data.uid || data.gender != CONST_GENDER_MALE || !data.isGtmTrack) return;

  const paramsBing = {
    userId: data.uid,
    event: "registration",
  };
  const paramsGoogle = {
    event: "auto_event_trigger",
    autoEventCategory: "Registration",
    autoEventAction: "Successful Registration",
    autoEventLabel: GTM_REG_METHOD_MAIL,
    userId: data.uid,
  };

  if (
    typeof window.dataLayer !== "undefined" &&
    typeof window.dataLayer.push === "function"
  ) {
    window.dataLayer.push(paramsGoogle);
    console.log("gtmRegistration -> ", paramsGoogle);
    paramsBing.event = "registration_bing";
    window.dataLayer.push(paramsBing);
    console.log("Bing gtmRegistration -> ", paramsBing);
  }
};

export const getBirthdayInFormat = (year, month, day) => {
  return (
    year +
    "-" +
    (month < 10 ? "0" + month : month) +
    "-" +
    (day < 10 ? "0" + day : day)
  );
};

export const getLandQuery = () => {
  let search = window.location.search ? window.location.search : null,
    cookie = getCookie(COOKIE_QUERY_NAME),
    href = window.location.href,
    queryList = {};

  if (search) {
    href = href.replace(search, "");
  }

  if (cookie && cookie.length > 0) {
    const params = cookie.split("&");

    if (params && params.length > 0) {
      for (var i = 0; i < params.length; i++) {
        var paramObj = params[i].split("=");

        if (paramObj[0] && paramObj[1]) {
          queryList[paramObj[0]] = paramObj[1];
        }
      }
    }
  }

  if (search) {
    if (search.substring(0, 1) == "?") search = search.substring(1);

    if (search && search.length > 0) {
      var params = search.split("&");

      if (params && params.length > 0) {
        for (var i = 0; i < params.length; i++) {
          var paramObj = params[i].split("=");

          if (paramObj[0] && paramObj[1]) {
            queryList[paramObj[0]] = paramObj[1];
          }
        }
      }
    }
  }

  if (Object.keys(queryList).length > 0) {
    Object.keys(queryList).forEach(function (param, index) {
      if (index <= 0) href += "?";
      else href += "&";

      href += param + "=" + queryList[param];
    });
  }

  return href;
};

export const prepareRegistrationData = (data) => {
  var trCookie =
      typeof window.simplyTrack !== "undefined" && window.simplyTrack.getTr
        ? window.simplyTrack.getTr()
        : "",
    affCookie = getCookie("srcuid");

  return {
    birthday: data.birthday,
    email: data.email,
    first_name:
      typeof decodeURI === "function"
        ? decodeURI(data.first_name)
        : data.first_name,
    gender: data.gender,
    language: getClientLanguage(),
    password: data.password,
    queries: window.location.href,
    queryLand: getLandQuery(),
    srcuid: affCookie ? affCookie : "",
    timezone: getTimeZone(),
    truid: trCookie,
  };
};

/**
 * Collect user click or other action on a site page
 * @param {string} event Event type identity
 * @param {null|Object} params Object with query params
 * @param {boolean} useTimeout If set true than execute action with timeout of 500ms
 * @return {boolean|null} Return false if event type not valid
 */
export const simplyClick = (event, params = null, useTimeout = true) => {
  console.log("---trackClick", event, params, useTimeout);

  if (window?.simplyTrack?.click && event) {
    if (useTimeout) {
      setTimeout(() => window.simplyTrack.click(event, params), 500);
    } else {
      window.simplyTrack.click(event, params);
    }
  }
};

//Get and Sets cookies
export const cookieStorage = {
  getItem: (key) => {
    const cookies = document.cookie
        .split(";")
        .map((cookie) => cookie.split("="))
        .reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key.trim()]: value,
            }),
            {}
        );

    return cookies[key];
  },
  setItem: (key, value) => {
    document.cookie = `${key}=${value};path=/`;
  },
};

export const decodeCookieString = (cookieString) => {
  let decodedString = decodeURIComponent(cookieString);

  // Replace single quotes around keys or values with double quotes for JSON compatibility
  decodedString = decodedString.replace(/'/g, '"');

  // Add double quotes around
  return decodedString.replace(/([{,]\s*)([a-zA-Z0-9]+)(\s*:)/g, '$1"$2"$3');
};

const isObject = (value) => {
  return typeof value === "object" && value !== null;
};

export const hasCookiebotDialog = () => {
  return !!document.getElementById("CookieBanner");
};

const isCookiebotBannerRequired = () => {
  const cookieConsentCookie = cookieStorage.getItem(cookieConsentPropertyType);

  if (!cookieConsentCookie || cookieConsentCookie === "") {
    return false;
  }

  const decodedString = decodeCookieString(cookieConsentCookie);

  const cookieObject = JSON.parse(decodedString);

  if (!isObject(cookieObject) || !cookieObject.hasOwnProperty("stamp")) {
    return false;
  }

  return cookieObject.stamp !== "-1";
};
export const hasConsented = () =>
    cookieStorage.getItem(consentPropertyType) === "true" ||
    isCookiebotBannerRequired();